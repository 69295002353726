*{
box-sizing:border-box
}

body{
margin: 0;
padding: 0;
font-family: "Poppins";
background-color:(lightblue);
}

form{
display: flexbox;
border-radius:20px;
background-color:#EAF4FC;
width: 100%;
}

span{
  color: #faa634 
}


.fixbot{
  position: absolute;
  bottom: 10px;
}

.LoginPage{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background:linear-gradient(skyblue,skyblue);
}




